import React from "react"
import { v4 as uuid } from "uuid"

import { Link, Logo } from ".."
import settings from "../../../settings"
import css from "./Header.module.sass"


export function Header({ location }) {
	const path = location ? location.pathname : null

	return (
		<header id="header" className={css.container}>
			<div>
				<Link to="/">
					<Logo
						fillColor="#414349"
						title="Aureus International Logo"
						desc="The letters 'AI' in the center with a wing on each side."
						className={css.logo}
					/>
				</Link>
			</div>

			<h1 className={css.title}>{settings.siteTitle}</h1>

			<address>
				<span className={css.phone}>
					<b>Phone:</b> <a href="tel:334-464-6200">(334) 464-6200</a>
				</span>
				<span className={css.address}>
					<b>Email:</b> <a href={`mailto:${settings.contactEmail}`}>{settings.contactEmail}</a>
				</span>
			</address>

			{settings.nav.length > 0 && (
				<nav className={css.nav}>
					<ul className={css.ul}>
						{settings.nav.map(i => {
							// Don't show the 'home' menu-item on home page
							if (path !== i.link) {
								return (
									<li className={css.li} key={uuid()}>
										<Link to={i.link} label={i.label}/>
									</li>
								)
							} else {
								return null
							}
						})}
					</ul>
				</nav>
			)}

		</header>
	)
}
