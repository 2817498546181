// Basic page structure
import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { Seo, Skiplink, Header, Footer } from "./index"


export function Layout({
	pageTitle,
	description,
	meta,
	author,
	location,
	children,
}) {

	return (
		<Fragment>
			<Seo
				title={pageTitle}
				description={description}
				meta={meta}
				author={author}
			/>

			<Skiplink />

			{/* Used for the image/linear-gradient in the background */}
			<div className="background_image"></div>

			<Header location={location} />

			{/* Page Content */}
			<main id="scroll-main" role="main">
				{children}
			</main>

			<Footer />

		</Fragment>
	)
}


Layout.propTypes = {
	children: PropTypes.node.isRequired,
}
