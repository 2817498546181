// This component is used only for making it easier
// to set up new SVG components
import React from "react"
import PropTypes from "prop-types"


export function OpenIcon({
	fillColor, title, desc, focusable, role,
	titleId, ariaHidden, className }) {
	return (
		<svg
			aria-labelledby={titleId}
			aria-hidden={ariaHidden}
			className={className}
			focusable={focusable}
			role={role}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 640 512"
		>

			{title && <title id={titleId}>{title}</title> }
			{desc && <desc>{desc}</desc>}

			<path fill={fillColor} d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"></path>
		</svg>
	)
}


OpenIcon.propTypes = {
	ariaHidden: PropTypes.string,
	className : PropTypes.string,
	desc      : PropTypes.string,
	focusable : PropTypes.string,
	role      : PropTypes.string,
	title     : PropTypes.string,
	titleId   : PropTypes.string,
}

OpenIcon.defaultProps = {
	ariaHidden: `true`,
	fillColor : `currentColor`,
	focusable : `false`,
	role      : `img`,
	titleId   : `titleId`,
}
