// Accessibility Skiplink
import React from "react"
import css from "./Skiplink.module.sass"


export function Skiplink() {
	return (
		<a className={css.container} href="#scroll-main">
			Jump to main content.
		</a>
	)
}
