module.exports = {
	siteTitle: `Aureus International`,
	description: `Protective clothing for professionals.`,
	defaultAuthor: `Tony Bradley`,
	googleAnalyticsId: `UA-177285942-1`,
	googleSearchConsole: `osMiB0rabfuYAbJljEC9RZnn4N72dYF3BiKKvONcdh8`,
	manifest: {
		backgroundColor: `#333333`,
		display: `minimal-ui`,
		icon: `static/images/icon-dark.png`,
		name: `Aureus International`,
		siteUrl: `https://ad20.aureusinternational.com/`,
		shortName: `aureus`,
		themeColor: `#333333`,
	},
	izziEmail: `info@workwithizzi.com`,
	contactEmail: `stevenh@aureusinternational.com`,
	tollFreePhone: {
		label: `(800) 448-9034`,
		link: `800-448-9034`,
	},
	localPhone: {
		label: `(334) 308-9500`,
		link: `334-308-9500`,
	},
	address: {
		number: `124`,
		street: `Commerce Drive`,
		city: `Enterprise`,
		state: `AL`,
		postal: `36330`,
	},
	corporateSite: {
		label: `Other Site`,
		link: `https://aureusinternational.com`,
	},
	nav: [
		{
			label: `Home`,
			link: `/`,
		},
		{
			label: `Casual Wear`,
			link: `https://www.companycasuals.com/aureuscorporatecasual/start.jsp`,
		},
		{
			label: `Contact`,
			link: `/contact`,
		},
	],
}
