import React from "react"

import settings from "../../settings"

const css = {
	footer: {
		margin: `12rem 0 1rem`,
	},
	creds: {
		display: `block`,
		textAlign: `center`,
		lineHeight: `2`,
	},
}



export function Footer() {

	const year = new Date().getFullYear()

	return (
		<footer style={css.footer}>
			<section>
				<small style={css.creds}>&copy; {year} Aureus International</small>
				<small style={css.creds}>Built by IZZI | <a href={`mailto:${settings.izziEmail}`}>Contact us.</a></small>
			</section>
		</footer>
	)
}


// Black Heart: &#9829;
// Heart Outline: &#9825;
