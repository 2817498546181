import React from "react"
import { Link } from "."

export function Button({
	to,
	link,
	label,
	type,
	className,
	children,
	...props
}) {

	let bClass = `button`
	if (className) {
		bClass = `button ${className}`
	}

	// If it's a link, make it an <a> tag
	if (link) {
		return (
			<Link className={bClass} to={to} {...props}>
				{label || children}
			</Link>
		)
	} else {
		return (
			<button className={bClass} type={type || `button`} {...props}>
				{label || children}
			</button>
		)
	}

}
