import React, { Fragment, useState } from "react"
import { OpenIcon, CloseIcon, Hide } from ".."

import css from "./ImageViewer.module.sass"

export function ImageViewer({ image }) {
	const [openState, setOpenState] = useState(false)
	const actionText = image.actionText || `Image`

	return (
		<Fragment>

			<button
				className={css.img_thumb_btn}
				onClick={() => setOpenState(true)}
			>
				<Hide>{`View ${actionText}`}</Hide>
				<OpenIcon />
				<img src={image.link} alt={image.alt} />
			</button>

			{openState && (
				<div className={css.popup_wrapper}>

					<div className={css.img_wrapper}>
						<img src={image.link} alt={image.alt} />
					</div>

					<button
						className={css.close_icon_btn}
						onClick={() => setOpenState(false)}
					>
						<Hide>{`Close ${actionText}`}</Hide>
						<CloseIcon />
					</button>

				</div>
			)}

		</Fragment>
	)
}
