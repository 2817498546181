import React from "react"
import css from "./ContentWrapper.module.sass"


export function ContentWrapper({ style, children }) {
	return (
		<section className={css.outer}>
			<div className={css.inner} style={style} >

				{children}

			</div>
		</section>
	)
}
