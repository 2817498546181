// Single-product page template:
import React from "react"
import { v4 as uuid } from "uuid"
import { ContentWrapper, Button, ImageViewer } from ".."

import css from "./ProductPage.module.sass"


export function ProductPage({ children, images }) {
	return (
		<ContentWrapper>
			<div className={css.container}>

				{/* Features/Details Section */}
				<div className={css.details_col}>
					{children}
					<Button
						label="Contact Us for More Information"
						to="/contact"
						link
					/>
				</div>

				{/* Images Section */}
				<div className={css.images_col}>
					{images.map(image => (
						<ImageViewer image={image} key={uuid()} />
					))}
				</div>

			</div>
		</ContentWrapper>
	)
}
