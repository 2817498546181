// SEO component that injects site metadata into <head>

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import settings from "../../settings"


export function Seo({
	description,
	lang,
	meta,
	title,
	author,
}) {

	const metaDescription = description || settings.description
	const pageAuthor = author || settings.defaultAuthor
	const SeoTitle = title ? `${settings.siteTitle} | ${title}` : `${settings.siteTitle}`

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			titleTemplate={`%s | ${settings.siteTitle}`}
			title={SeoTitle}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: SeoTitle,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: pageAuthor,
				},
				{
					name: `twitter:title`,
					content: SeoTitle,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: `google-site-verification`,
					content: settings.googleSearchConsole,
				},
			].concat(meta)}
		/>
	)
}


Seo.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}


Seo.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
}
